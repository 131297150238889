


































































































































var maestroKtb = "maestroKtb"
import {Vue,Component,Prop} from 'vue-property-decorator'
import {Espesor} from '@/entidades/Maestro/Ktb/Espesor';
import {Largo} from '@/entidades/Maestro/Ktb/Largo';
import {Linea} from '@/entidades/Maestro/Ktb/Linea';
import {Mesa} from '@/entidades/Maestro/Ktb/Mesa';
import {Validations} from 'vuelidate-property-decorators';
import {minValue, required} from 'vuelidate/lib/validators'
import {Action,State} from 'vuex-class';
import Alerta from '@/entidades/Sistema/Alerta';
import Loading from '@/entidades/Sistema/Loading';
import {GuardarPallet, ProduccionRequest} from '@/entidades/KTB/Produccion/ProduccionQuery';
import TurnoAperturaResponse from '@/entidades/KTB/Turno/AperturaTurno';
import { TurnoQuery } from '@/entidades/KTB/RegistroInformacion/Turno';
import Empleado from '@/entidades/Plantacion/Empleado';
import AnchoResponse from '@/entidades/Maestro/Ktb/Ancho';
import { LargoOpcionModel } from '@/entidades/Maestro/Ktb/LargoOpcions';
@Component
export default class ProduccionAdd extends Vue
{
    @Prop({type:Array,required:true}) listadoLargo!:Array<Largo>;
    @Prop({type:Array,required:true}) listadoEspesor!:Array<Espesor>;
    @Prop({type:Number,required:true}) plantaId!:number;
    @Prop({type:Object,required:false}) aperturaTurno!:TurnoAperturaResponse;
    @Prop({type:String,required:false}) procedencia!:string;
    @Prop({type:Number,required:false}) recepcionId!:number;
    @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function;
    @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function;
    @State('usuarioId',{namespace: 'authModule'}) usuarioId!:string;
    @State('mesa',{namespace: maestroKtb}) mesa!:Array<Mesa>;
    @State('linea',{namespace: maestroKtb}) linea!:Array<Linea>;
    @State('turno',{namespace: maestroKtb}) turno!:Array<TurnoQuery>;
    @State('empleados',{namespace: maestroKtb}) empleados!:Array<Empleado>;
    @State('anchos',{namespace: maestroKtb}) anchos!:Array<AnchoResponse>;
    @State('largoOpcions',{namespace: maestroKtb}) largoOpcion!:Array<LargoOpcionModel>;
    opcionId:number = 1;
    espesorId:number=0;
    largoId:number=0;
    mesaId:number=0;
    lineaId:number=0;
    largoCalculo:number=0;
    ancho:number=0;
    anchoValor = 0;
    empleadoId:number=0;
    numPlantilla:number=0;
    listadoLinea:Array<Linea> =[];
    listadoMesa:Array<Mesa> =[];
    completo:boolean = true;
    opcionDescripcion:string="";

        //Configuracion de validacion
  @Validations()
     validations = {
         espesorId : {required,minValue:minValue(1)},
         largoId : {required,minValue:minValue(1)},
         mesaId : {required,minValue:minValue(1)},
         lineaId : {required,minValue:minValue(1)},
         empleadoId : {required,minValue:minValue(1)},
         numPlantilla : {required,minValue:minValue(1)},
         ancho : {required,minValue:minValue(1)}
     }

     async guardar()
     {
         try
         {
            this.changeLoading(new Loading(true,"Registrando informacion...Espere por favor"));
            let request:ProduccionRequest = new ProduccionRequest(this.plantaId,this.usuarioId,this.largoId,
                                    this.espesorId,this.mesaId,this.lineaId,this.numPlantilla,this.anchoValor,
                                    Math.round(Number(this.volumen)),
                                    this.empleadoId,this.aperturaTurno.aperturaTurnoId,
                                    this.procedencia,this.recepcionId
                                    );

            let rsp = await GuardarPallet(request);
            if(rsp.isSuccess == true)
            {
                this.changeAlerta(new Alerta(rsp.isMessage,true,"success",3000));
                this.$emit('limpiar');
            }
            else
                this.changeAlerta(new Alerta(rsp.isMessage,true,"danger",3000));

         }
         catch(error)
         {

         }
         finally
         {
             this.changeLoading(new Loading(false,""));
         }
     }


     cambiarNumeroPlantilla()
     {
         if(this.espesorId > 0)
         {
             var espesor = this.listadoEspesor.find(c=>c.espesorId == this.espesorId);
             this.numPlantilla = espesor?.plantilla!;
         }
     }

    get factorHueco()
    {
        var espesor = this.listadoEspesor.find(c=>c.espesorId == this.espesorId);
        return espesor?.factorHueco;
    }

    get volumen()
    {
        var calculo:number =0;
        var largo = this.listadoLargo.find(c=>c.largoId == this.largoId);
        var espesor = this.listadoEspesor.find(c=>c.espesorId == this.espesorId);
        var largoCalculo = this.opcionDescripcion == "PULGADACOMERCIAL" ? largo?.pulgadaComercial : this.opcionDescripcion == "PULGADA" ? largo?.pulgada : largo?.pie;
        this.anchoValor = this.anchos.find(c=>c.anchoId == this.ancho)?.valor!;
        if(this.opcionDescripcion == "PIES")
        {
            calculo = Math.round((Number(largoCalculo) * this.anchoValor * Number(espesor?.pulgada) * this.numPlantilla * Number(espesor?.factorHueco)) /12);
        }
        else
        {
            calculo = Math.round(((Number(largoCalculo) * this.anchoValor * Number(espesor?.pulgada) * this.numPlantilla)/144)* Number(espesor?.factorHueco));
        }
        var numero = Math.ceil(calculo);
        return numero;
    }

    get turnoAsignado()
    {
        if(this.aperturaTurno != undefined)
        {
            var turno = this.turno.find(c=>c.turnoId == this.aperturaTurno.turnoId);
            return turno?.descripcion;
        }
        else 
            return "";
    }

    get habilitarAncho()
    {
        if(this.anchos.length == 1)
        {
            var anchotmp = this.anchos[0];
            this.ancho =anchotmp.anchoId;
            return true;
        }
        else 
        {
            
            return false;
        }
    }

    get validarNumPlantilla()
    {
        if(this.completo)
        {
            return true;
        }
        else 
        {
            return false;
        }
    }
    get descripcionLargo()
    {
        this.opcionDescripcion = this.largoOpcion.find(c=>c.opcionId == this.opcionId)?.descripcion!;
        if(this.opcionDescripcion == "PULGADACOMERCIAL")
        {
            return "descripcionPulgadaComercial";
        }
        else if(this.opcionDescripcion == "PULGADA")
        {
            return "descripcionPulgada";
        }
        else 
        {
            return "descripcion";
        }
    }
    async mounted() 
    {
       // this.listadoMesa = (await ObtenerMesaSelect(this.plantaId)).isResult;    
       // this.listadoLinea = (await ObtenerLineaSelect(this.plantaId)).isResult;
    }
}
